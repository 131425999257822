<template>
  <FLSelection
    :option="Object.values(locales)"
    :value="selected"
    :title="$t('label.lang')"
    default
    @input="switchLocale"
  />
</template>

<script>
import Selection from "@/components/modules/form/Selection";
import FLSelection from "@/components/widgets/FLSelection";

export default {
  components: {
    FLSelection
  },
  methods: {
    switchLocale(locale) {
      console.log(locale);
      if (this.$i18n.locale !== locale.value) {
        this.$i18n.locale = locale.value;
        localStorage._lang = locale.value;
      }
    },
  },
  computed: {
    selected() {
      return this.locales[this.$i18n.locale];
    },
  },
  data() {
    return {
      locales: {
        en: { label: "EN", value: "en" },
        zh: { label: "中文", value: "zh" },
      },
      dataTypeMap: {
        object: Selection,
      },
    };
  },
};
</script>
