<template>
  <div class="widget--fl_selection" :class="{ 'selected': selected && Object.keys(selected).length > 0 }">
    <div class="inner-wrap">
      <div class="selected option" @click="open">
        <!-- <div class="label">{{ selected.label ? selected.label : setup && setup.label ? selected[setup.label] : selected.value }}</div> -->
        <div class="select-wrap" v-if="selected && Object.keys(selected).length > 0">
          <div class="clear-btn" @click="clear" v-if="closeable">
            <fa :icon="['fas','times-circle']"/>
          </div>
          <div class="label">{{  getTranslatedVal({...setup,label}, selected) }}</div>
        </div>
        <div class="selection--label">{{ titleLabel }}</div>
        <div class="space"></div>
        <div class="arrow" :class="{ 'show': show }">
          <fa :icon="['fas','chevron-down']"/>
        </div>
      </div>
      <div class="option-wrapper" :class="{ 'show': show }">
        <div class="option"
          v-for="(opt,index) in filtered_options"
          :key="index"
          :class="{ 'active': selected && setup && opt[setup.value] == selected[setup.value] }"
          @click="select(index)">
          <!-- {{ opt.label ? opt.label : setup && setup.label ? opt[setup.label] : opt.value }} -->
          <span>{{ getTranslatedVal({...setup,label}, opt) }}</span>
          </div>
          <div class="option not_found" v-if="filtered_options.length == 0">
            <div class="label">{{ $t("message.no_suitable_option_found") }}</div>
          </div>
      </div>
    </div>
    <div class="background-layer" :class="{ 'show': show }" @click="show=false"></div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Array,
      default() {
        return [];
      }
    },
    default: {
      type: Boolean,
      default() {
        return false;
      }
    },
    setup: Object,
    value: [Object,Number,String],
    disabled: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default() {
        return false
      }
    },
    filterable: {
      type: Boolean,
      default() {
        return false
      }
    },
    title: String
  },
  beforeMount() {
    this.selected = this.value;
    if(this.default && this.option.length > 0 && !this.selected) {
      this.selected = this.option[0];
    }
    // sorting
    this.option.sort((a,b)=>{
      let al = a[this.label]?.toUpperCase();
      let bl = b[this.label]?.toUpperCase();

      if(al<bl) return -1;
      if(al>bl) return 1;
      return 0;
    });
    this.$emit("input", this.selected);
  },
  methods: {
    open() {
      if(this.disabled) {
        this.show = false;
        return;
      }
      this.show = !this.show;
    },
    close() {
      this.show = false;
    },
    select(index) {
      this.selected = this.filtered_options[index];
      this.$emit("input", this.selected);
      this.filter = "";
      this.close();
    },
    clear(e) {
      console.log("clear");
      this.selected = {};
      this.$emit("input", this.selected);
      e.stopPropagation();
    }
  },
  computed: {
    label() {
      if(typeof this?.setup?.label == "undefined") return "";
      let label = this.setup.label;
      let split = label.split(".");
      if(split.length > 1) {
        label = split[split.length-1];
      }
      return label;
    },
    filtered_options() {
      if(!this.filterable) return this.option;
      let langMap = this?.setup?.locale || {};
      if(typeof this.setup.locale == "boolean") {
        langMap = {
          en: "_en",
          zh: "_ch"
        };
      }
      let localeLabel = `${this.label}${langMap[this.$i18n.locale]||""}`;
      return this.option.filter(opt=>opt[localeLabel]?.includes(this.filter)).sort((a,b)=>`${a[localeLabel]}`.localeCompare(b[localeLabel]));
    },
    titleLabel() {
      return this.tran(this.$route.params.key, this?.setup?.key) || this.title || "";
    }
  },
  data() {
    return {
      show: false,
      selected: {},
      filter: ""
    }
  }
}
</script>

<style lang="scss">
.widget--fl_selection{
  .inner-wrap{
    position: relative;
    width: 150px;
    border: 1px solid #aaa;
    border-radius: 4px;
    background: white;
    height: 35px;
    display: flex;
    .selected{
      display: flex;
      align-items: center;
      justify-content: space-between;
      // z-index: 2;
      overflow: hidden;
      .select-wrap{
        display: flex;
        overflow: hidden;
        .clear-btn{
          margin-right: 7px;
          transition: color .2s ease;
          opacity: .7;
          color: #330000;
          &:hover{
            opacity: .4;
          }
        }
      }
      .placeholder{
        color: #aaa;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        input{
          width: 85%;
          border: 0px;
          &[placeholder]{
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
          }
          
          &:focus{
            outline: none !important;
            border: none;
          }
        }
      }
      .space{
        flex-grow: 1;
      }
      .arrow{
        transition: transform .2s ease-in-out;
        &.show{
          transform: rotateZ(-180deg);
        }
      }
      .selection--label{
        transition: top .1s cubic-bezier(.25,.8,.5,1), background .1s .1s cubic-bezier(.25,.8,.5,1);
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background: initial;
        color: rgba(0,0,0,.6);
      }
    }
    .option-wrapper{
      z-index: 3;
      position: absolute;
      top: 40px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      height: 0;
      background: white;
      min-width: 100%;
      max-height: 120px;
      overflow-y: auto;
      border: 1px solid #eee;
      box-shadow: 1px 1px 5px #ccc;
      border-radius: 4px;
      transform: scaleY(0);
      transform-origin: center top;
      transition: visibility .1s linear, opacity .1s linear, transform .1s linear;
      overflow-x: hidden;
      &.show{
        opacity: 1;
        visibility: visible;
        height: auto;
        transform: scaleY(1);
      }
      > .option{
        &.active{
          background: #d1d1d1;
        }
        &:not(.not_found):not(.active):hover{
          background: #f1f1f1;
        }
      }
    }
    .option{
      flex-grow: 1;
      padding: 0 10px;
      font-size: 16px;
      height: 35px;
      display: flex;
      align-items: center;
      user-select: none;
      &:not(.not_found) {
        cursor: pointer;
      }
      span,
      .label{
        min-width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    &.disabled--component{
      background: #ededed;
      .selected{
        cursor: default;
        user-select: auto;
        .label{
          color: #777;
        }
        .arrow{
          color: #777;
        }
      }
    }
  }
  .background-layer{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: none;
    &.show{
      display: block;
    }
    // background: black;
  }
  &.selected{
    .selection--label{
      position: absolute;
      top: -9px!important;
      left: 6px;
      background: white!important;
      font-size: 12px;
      padding: 0 5px;
      height: auto!important;
    }
  }
}
</style>