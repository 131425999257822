<template>
  <div class="layout--plain">
    <Spinner :load="load"/>
    <Notification
      v-for="(noti,index) in notification.msg"
      :key="index"
      :position="(notification.msg.length-1 - index)*75 + 10"
      :title="noti.title"
      :message="noti.msg"
      :type="noti.type"/>
    <div id="top">
      <NavBar :plain="true"/>
    </div>
    <div id="main">
      <div id="nav" :loading="!ready">
        <!-- <router-link v-for="page in Object.keys($setting.crud)" :key="page" :to="page">{{ page }}</router-link> |       -->
        <!-- <Sidebar ref="sidebar"/> -->
      </div>
      <div id="content-wrapper" :loading="!ready || load">
        <!-- <div class="timebar"> -->
          <!-- <div class="date">{{ $moment() }}</div> -->
        <!-- </div> -->
        <div id="content">
          <router-view :key="$route.fullPath" v-if="ready"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/layouts/NavBar";
import Sidebar from "@/components/layouts/Sidebar";
import { mapState } from 'vuex';
import Notification from "@/components/widgets/Notification";

export default {
  components: {
    NavBar,
    Sidebar,
    Notification
  },
  methods: {
    logout() {
      this.$confirm(this.$t("message.logout_warning"), this.$t("label.logout"), {
        confirmButtonText: this.$t("label.logout"),
        cancelButtonText: this.$t("button.cancel"),
        type: 'warning'
      }).then(async () => {
        await this.$axios.post(`logout`).catch(err=>err);
        localStorage.removeItem("lt");
        this.$axios.defaults.headers.common["Authorization"] = "";
        this.go("login");
      });
    },
    toggleNav() {
      // console.log("toggle in default layout");
      // this.$refs.sidebar.toggleNav();
      this.$store.commit("control/setSidebar", !this.sidebar);
    }
  },
  computed: {
    ...mapState({
      username: state => state.user.user_info.name,
      roles: state => state.user.user_info.roles,
      sidebar: state => state.control.sidebar,
      ready: state => state.status.ready,
      load: state => state.control.load,
      notification: state => state.notify.notification,
    }),
    usr_max_role() {
      let role = {};
      this.roles.map(r=>{
        if(typeof role.level != "undefined") {
          if(r.level > role.level) {
            role = r;
          }
        }else{
          role = r;
        }
      });
      return role;
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.layout--plain{
  min-height: 100%;
  display: flex;
  flex-direction: column;
  #top{
    
  }
  #main{
    display: flex;
    flex-grow: 1;
    #content-wrapper{
      overflow: auto;
      flex-grow: 1;
      // border: 1px solid  var(--layout-border-color);
      display: flex;
      flex-direction: column;
      .timebar{
        background-color: whitesmoke;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
        .date{
          font-size: 14px;
        }
      }
      &>#content{
        margin: 10px;
        // padding: 10px;
        flex-grow: 1;
        // overflow-y: auto;
      }
    }
  }
}
</style>
