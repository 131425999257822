<template>
  <div class="widget--notification" :style="`top:${position}px;`" :class="`type--${type}`">
    <div class="inner-wrap">
      <div class="title">{{ title }}</div>
      <div class="message">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ["title","message","type","position"],
  beforeMount() {
    setTimeout(()=>{
      this.$store.commit("notify/dismissNotification");
    }, 5000);
  },
  computed: {
    ...mapState({
      notification: state => state.notify.notification,
    })
  },
}
</script>

<style lang="scss" scoped>
@keyframes fadeout {
  from {
    opacity: 1;
    right: 10px;
    visibility: visible;
  }
  to {
    opacity: 0;
    right: -200px;
    visibility: hidden;
  }
}

.widget--notification{
  position: fixed;
  right: 10px;
  min-width: 150px;
  max-width: 300px;
  height: 55px;
  overflow: hidden;
  background: white;
  z-index: 1011;
  box-shadow: 0px 0px 5px #aaa;
  border-radius: 5px;
  transition: top .2s ease;
  display: flex;
  align-items: center;
  padding: 5px 25px;
  .inner-wrap{
    text-overflow: ellipsis;
    line-height: .9em;
    flex-grow: 1;
    .title{
      font-size: 14px;
      font-weight: bold;
    }
    .message{
      font-size: 16px;
      line-height: 1.2em;
    }
  }
  color: white;
  &.type--success{
    background: #5aba7e;
  }
  &.type--error{
    background: #da605b;
  }
}
</style>