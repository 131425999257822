<template>
  <div class="component_layout--navbar">
    <Popup class="logout--popup" ref="logout_popup" v-slot:default="{ close }">
      <div class="popup-inner-wrap">
        <div class="title">{{ $t("button.logout") }}</div>
        <div class="message" v-html="$t('message.logout_warning')"></div>
        <div class="action-btn">
          <div class="button cancel" @click="close">{{ $t("button.cancel") }}</div>
          <div class="button remove" @click="logout">{{ $t("button.logout") }}</div>
        </div>
      </div>
    </Popup>
    <div class="left">
      <div class="bar" @click="toggleSidebar" v-if="loggedin && !plain">
        <fa :icon="['fas',sidebar ? 'bars' : 'arrow-right']" size="lg"/>
      </div>
      <!-- <div class="logo" @click="go('')">
        <img src="@/assets/logo_EPD_color.png">
      </div> -->
      <div class="back-btn" v-if="plain && loggedin" @click="go('')">
        <fa :icon="['fas','chevron-left']" size="lg"/>
        <div class="label" v-t="'button.back'"></div>
      </div>
    </div>
    <div class="right" v-if="loggedin">
      
      <div class="space"></div>
      <LocaleSwitcher/>
      <div class="user-wrap">
        <div class="avatar">
          <div class="wrap">
            <!-- <div class="circle"></div> -->
            <!-- <div class="text">{{ username[0] }}</div> -->
            {{ username[0] }}
          </div>
        </div>
        <div class="name">{{ username }}</div>
        <div class="dropdown-wrapper">
          <div class="item" @click="go('user/profile')">{{ $t("button.user_profile") }}</div>
          <div class="item" @click="clickedLogout">{{ $t("button.logout") }}</div>
        </div>
      </div>
      <!-- <div class="shortcut-menu">
        <router-link class="item" :to="'/account'">{{ $t("menu.top_bar.user_account") }}</router-link>
        <router-link class="item" :to="'/account'">{{ $t("menu.top_bar.operation") }}</router-link>
      </div> -->
      <!-- <div class="search-wrap">
        <input :placeholder="$t('button.search')"/>
        <div class="search-icon">
          <fa :icon="['fas','search']" size="lg"/>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LocaleSwitcher from "../widgets/LocaleSwitcher";

export default {
  props: {
    plain: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  components: {
    LocaleSwitcher,
  },
  computed: {
    ...mapState({
      username: state => state.user.user_info.name,
      roles: state => state.user.user_info.roles,
      sidebar: state => state.control.sidebar,
      ready: state => state.status.ready,
      load: state => state.control.load,
      loggedin: state => state.status.loggedin
    }),
  },
  methods: {
    clickedLogout() {
      this.$refs.logout_popup.show();
    },
    async logout() {
      this.needLoading(true);
      await this.$axios.post(`/system_user/logout`);
      localStorage.removeItem("lt");
      this.needLoading(false);
      this.$store.commit("status/setLoggedIn", false);
      this.$router.push("/login");
    },
    toggleSidebar() {
      this.$store.commit("control/setSidebar",!this.sidebar);
    }
  }
}
</script>

<style lang="scss">
.component_layout--navbar{
  height: 90px;
  display: flex;
  padding: 0 7px;
  padding-left: 0px;
  .left{
    display: flex;
    // width: 250px;
    .bar{
      width: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .back-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 70px;
      user-select: none;
      padding: 0 20px;
      .label{
        margin-left: 10px;
      }
    }
  }
  .right{
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 20px;
    .user-wrap{
      display: flex;
      padding: 0 15px;
      position: relative;
      .avatar{
        background: whitesmoke;
        border-radius: 50%;
        .wrap{
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: capitalize;
          font-size: 20px;
        }
      }
      .name{
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 16px;
      }
      .dropdown-wrapper{
        position: absolute;
        top: 40px;
        right: 0;
        // width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        transition: all .2s ease;
        background: white;
        // height: 100px;
        padding: 5px;
        box-shadow: 0px 1px 3px #ddd;
        border-radius: 3px;
        transform: translateY(-15px);
        .item{
          background: white;
          height: 35px;
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          user-select: none;
          align-self: self-start;
          transition: all .2s ease;
          font-size: 14px;
          &:hover{
            background: #ddd;
          }
        }
      }
      &:hover{
        .dropdown-wrapper{
          visibility: visible;
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
    .shortcut-menu{
      margin-right: 10px;
      a{
        text-decoration: none;
        color: black;
        width: 120px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: background-color .2s ease-in-out;
        &:hover{
          background-color: #f7f7f7;
        }
      }
    }
    .space{
      flex-grow: 1;
    }
    .search-wrap{
      position: relative;
      width: 230px;
      border: 1px solid #d9d9d9;
      input{
        border-radius: 3px;
        border: 1px solid #fff;
        background: white;
        height: 40px;
        width: calc(100% - 50px);
        margin-left: 10px;
        &:focus{
          outline: none !important;
          border: 1px solid #fff;
        }
      }
      .search-icon{
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .logout--popup{
    .inner-wrap{
      width: 450px;
      max-width: 95%;
      height: 150px;
      background: white;
      border-radius: 5px;
      .popup-content{
        flex-grow: 1;
      }
      .popup-inner-wrap{
        // height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .title{
          display: flex;
          /* justify-content: center; */
          /* align-items: center; */
          padding: 10px;
          font-size: 18px;
          font-weight: bold;
          
        }
        .message{
          flex-grow: 1;
          display: flex;
          justify-content: center;
          /* align-items: center; */
          /* padding: 0 10px; */
          font-size: 16px;
        }
        .action-btn{
          display: flex;
          padding: 10px 10px;
          gap: 10px;
          .button{
            border-radius: 5px;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            color: white;
            user-select: none;
            cursor: pointer;
            &.cancel{
              background: #aaa;
            }
            &.remove{
              background: rgb(231, 0, 0);
            }
            &.restore{
              background: rgb(252, 186, 3);
            }
          }
        }
      }
    }
    .close-btn{
      display: none;
    }
  }
}
</style>